$couleur_une: #9e9e9e;
$couleur_deux: #fff;
$couleur_trois: #000;
$couleur_quatre: #3c3c3c;
$couleur_cinq: #7d7d7d;
$couleur_six: #2d2d2d;
$couleur_sept: #3a3a3a;
$couleur_huit: #fbfbfb;
$couleur_neuf: #eee;

$menu_principal_hover_underline: none;
$menu_principal_li_border_right: 1px solid;

/* Général */

$body_background_color : $couleur_deux;
$body_text_color : $couleur_trois;
$content_background_color: $couleur_deux;
$texte_lien:$couleur_trois;
$texte_lien_hover:lighten($couleur_trois,10%);
$header_background:$couleur_deux;
$slogan_color_texte:$couleur_une;
$titre_color:$couleur_trois;
$prixprod_color: $couleur_une;
$oldprix_color: $couleur_quatre;
$global_bouton_background: $couleur_une;
$global_bouton_color: $couleur_deux;
$global_bouton_border_color: $couleur_une;
$addbasket_bouton_background: $couleur_une;
$addbasket_bouton_color: $couleur_deux;
$fil_ariane_color: $couleur_cinq;
$fil_ariane_color_hover: $couleur_une;
$wraptitles_and_subtitles_color: $couleur_une;


/*Panier*/

$panier_title_color: $couleur_trois;
$panier_background_color : transparent;
$panier_count_live_background: $couleur_trois;
$panier_count_live_color: $couleur_deux;
$panier_content_background_color: $couleur_deux;


/* Slider */

$background_bandeau_menu_slider_home: rgba(0,0,0,0.2);
$bouton_slider_color: $couleur_une;
$slider_nav_bouton_background: $couleur_deux;
$slider_nav_bouton_color: $couleur_quatre;
$slider_nav_bouton_color_hover: $couleur_une;

/* Menu */

// Principal & secondaire
$sous_menu_li_background_color: $couleur_deux;
$sous_menu_li_background_color_hover: $couleur_deux;

// principal
$nav_background_color:transparent;
$nav_texte_color:$couleur_trois;
$nav_texte_color_hover: $couleur_une;
$li_background_color: transparent;
$li_background_color_hover: transparent;


// Secondaire
$nav_second_background_color: $couleur_une;
$nav_second_background_color_bouton_principal: $couleur_six;
$nav_second_background_color_bouton_principal_hover: $couleur_sept;
$nav_second_color_bouton_principal: $couleur_deux;
$secondary_li_background_color:transparent;
$secondary_li_background_color_hover:transparent;
$secondary_texte_color:$couleur_deux;
$secondary_texte_color_hover:$couleur_deux;
$nav_panier_color: $couleur_deux;
$nav_panier_bullet_background: $couleur_six;
$nav_panier_bullet_color: $couleur_deux;


/* Menu responsive */
$burger_background_color:$couleur_deux;
$burger_texte_color:$couleur_trois;
$dropdown_color:$couleur_deux;
$dropdown_color_hover:$couleur_trois;

/* Produits */

$boutique_background: $couleur_deux;
$mask_background:rgba(255,255,255,0.5);
$mask_lien_color:$couleur_deux;
$etiquette_produit_background:$couleur_une;
$etiquette_produit_texte:$couleur_deux;
$remise_background_color:$couleur_une;
$remise_texte_color:$couleur_deux;
$texte_courant_color: $couleur_quatre;
$boutons_description_template_3_background: $couleur_deux;
$boutons_description_template_3_background_hover: $couleur_une;
$boutons_description_template_3_color: $couleur_une;
$boutons_description_template_3_color_hover: $couleur_deux;


// Avis client

$avis_background: $couleur_deux;
$avis_text_color: $couleur_trois;
$avis_etoiles_color: $couleur_une;
$avis_username_color: $couleur_quatre;


/* Galerie photo*/
$galerie_texte_color:$couleur_deux;
$galerie_mask_background:$couleur_une;

/* Livre d'or */
$message_lo_color: $couleur_une;

/* Blog / Boutons */
$btn_background_color:$couleur_trois;
$btn_texte_color:$couleur_deux;

/* Footer */
$footer_background_color: transparentize($couleur_une,0.1);
$footer_texte_color:$couleur_deux;

/* Colonne de gauche */
$colonne_gauche_border: rgba(0,0,0,0.1);
$sidebar_menu_color: $couleur_cinq;
$sidebar_menu_color_hover: $couleur_une;
$sidebar_li_background: $couleur_deux;
$sidebar_li_background_hover: $couleur_huit;
$li-isactif_background: $couleur_neuf;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/819series/declinaison";